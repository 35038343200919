<template>
	<section class="invoice-add-wrapper">
	  <b-row class="invoice-add">
		
		<!-- Col: Left (Invoice Container) -->
		<b-col
		  cols="12"
		  xl="9"
		  md="8"
		>
		  <b-form @submit.prevent>
			<b-card
			  no-body
			  class="invoice-preview-card"
			>
			  <!-- Header -->
			  <b-card-body class="invoice-padding pb-0">
				<div class="row">
					<div class="col-6">
					  <span class="font-weight-bold mt-2">
						<feather-icon icon="CalendarIcon" size="16"/> Seleccione una Fecha de Inicio:
					  </span>
					  <flat-pickr
						v-model="invoiceData.fecha_i"
						class="form-control mt-1"
						style="cursor: pointer;"
						width="100%"
					  />
				  </div>
                  <div class="col-6">
					  <span class="font-weight-bold mt-2">
						<feather-icon icon="CalendarIcon" size="16"/> Seleccione una Fecha Final:
					  </span>
					  <flat-pickr
						v-model="invoiceData.fecha_f"
						class="form-control mt-1"
						style="cursor: pointer;"
						width="100%"
					  />
				  </div>						
				</div>

				<div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
  
				 	  
				</div>
			  </b-card-body>
  
			  <!-- Spacer -->
			  <hr class="invoice-spacing">
  
			  <!-- Items Section -->
			  <b-card-body class="invoice-padding form-item-section">
				<object
				type="application/pdf"
				width="100%"
				height="800px"
				style="border: 1px solid #ddd"
				:data="iframeSrc"
				class="rounded mb-50"
				></object>
				
			  </b-card-body>
  
			
  
			  <!-- Spacer -->
			  <hr class="invoice-spacing">
  
			</b-card>
		  </b-form>
		</b-col>
  
		<!-- Right Col: Card -->
		<b-col
		  cols="12"
		  md="4"
		  xl="3"
		  class="invoice-actions mt-md-0 mt-2"
		>
  
		  <!-- Action Buttons -->
		  <b-card>
  
			<!-- Button: Send Invoice -->
			<b-button
			  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
			  variant="primary"
			  class="mb-75"
			  block
			  @click="consultarReporte"
			>
			<feather-icon icon="FileIcon" class=""/> Consultar Reporte 
			</b-button>
  
			<!-- Button: Print 
			<b-button
			  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
			  variant="outline-primary"
			  block
			>
			<feather-icon icon="PrinterIcon" class=""/>  Imprimir para Firmas
			</b-button>-->
		  </b-card>

		  <!-- <pre>
			{{ invoiceData }}
		  </pre> -->
		</b-col>
	  </b-row>
	</section>
  </template>
  
  <script>
  import Logo from '@core/layouts/components/Logo.vue'
  import { ref, onUnmounted } from '@vue/composition-api'
  import { heightTransition } from '@core/mixins/ui/transition'
  import Ripple from 'vue-ripple-directive'
  import store from '@/store'
  import {
	BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle, BEmbed
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import flatpickr1 from "flatpickr";
	import { Spanish } from "flatpickr/dist/l10n/es.js"
	flatpickr1.localize(Spanish); // default locale is now Spanish
  import flatPickr from 'vue-flatpickr-component'
  import invoiceStoreModule from '../../apps/invoice/invoiceStoreModule'
  import axios from '@axios'
  
  export default {
	components: {
		BRow,
		BCol,
		BCard,
		BCardBody,
		BButton,
		BCardText,
		BForm,
		BFormGroup,
		BFormInput,
		BInputGroup,
		BInputGroupPrepend,
		BFormTextarea,
		BFormCheckbox,
		BPopover,
		flatPickr,
		vSelect,
		Logo,
		BEmbed
	},
	directives: {
		Ripple,
		'b-toggle': VBToggle, 
	},
	mixins: [heightTransition],
	mounted() {
		//this.initTrHeight(),
		//this.getCentrosTrabajo(),
		//this.getEmpleados()
	},
	methods: {
	consultarReporte(){
		axios.get('/api/reportes/reporte-totales-mallas?fecha_i='+this.invoiceData.fecha_i+'&fecha_f='+this.invoiceData.fecha_f).then(
			(response) => {
				//get blov url ajd set iframe src
				this.iframeSrc = response.data;
			}
		).catch(
			(error) => {
				this.$toast.error('Ocurrio un error al consultar el reporte');
				this.iframeSrc = '';
			}
		);
	},
	formatMoney(value) {
				//format to mexican currency
				var formatter = new Intl.NumberFormat('es-MX', {
				style: 'currency',
				currency: 'MXN',
				minimumFractionDigits: 2
				});

				return formatter.format(value);
			},
	  
	},
	setup() {
	  const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'
  
	  // Register module
	  if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
  
	  // UnRegister on leave
	  onUnmounted(() => {
		if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
	  })
  
	  /*const clients = ref([])
	  store.dispatch('app-invoice/fetchClients')
		.then(response => { clients.value = response.data })*/
  
	  const itemFormBlankItem = {
		empleado: null,
		horas_trabajadas: null,
		pago: 0,
		observacion: '',
	  }

	  const datePickerConfig = {
		enableTime: false,
		dateFormat: 'Y-m-d',
		language: 'es',
	  }
  
	  const invoiceData = ref({
		id: null,
		fecha_i: new Date().toISOString().substr(0, 10),
        fecha_f: new Date().toISOString().substr(0, 10),
		centro_costo_id: null,
  
		// ? Set single Item in form for adding data
		items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
  		observaciones: '',
	  })
  
	  const updateItemForm = (index, val) => {
		const { cost, qty, description } = val
		invoiceData.value.items[index].cost = cost
		invoiceData.value.items[index].qty = qty
		invoiceData.value.items[index].description = description
	  }
  
	  const paymentMethods = [
		'Bank Account',
		'PayPal',
		'UPI Transfer',
	  ]
  
	  return {
		invoiceData,
		//clients,
		//itemsOptions,
		updateItemForm,
		itemFormBlankItem,
		paymentMethods,
		datePickerConfig
	  }
	},
	data() {
	  return {
		centros_trabajo: [],
		itemsEmpleados : [],
		iframeSrc : ""
	  }
	},
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .invoice-add-wrapper {
	.add-new-client-header {
	  padding: $options-padding-y $options-padding-x;
		color: $success;
  
	  &:hover {
		background-color: rgba($success, 0.12);
	  }
	}
  }
  </style>
  
  <style lang="scss" scoped>
  @import "~@core/scss/base/pages/app-invoice.scss";
  @import '~@core/scss/base/components/variables-dark';
  
  .form-item-section {
  background-color:$product-details-bg;
  }
  
  .form-item-action-col {
	width: 27px;
  }
  
  .repeater-form {
	// overflow: hidden;
	transition: .35s height;
  }
  
  .v-select {
	&.item-selector-title,
	&.payment-selector {
	  background-color: #fff;
  
	  .dark-layout & {
		background-color: unset;
	  }
	}
  }
  
  .dark-layout {
	.form-item-section {
	  background-color: $theme-dark-body-bg;
  
	  .row .border {
		background-color: $theme-dark-card-bg;
	  }
  
	}
  }
  </style>
  